<template>
  <div>
    <div id="loading-screen" class="visible">
      <svg
        width="243"
        height="48"
        viewBox="0 0 243 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M71.5071 10.1547H65.9018V21.1525H63.3583V10.1547H57.7432V7.71909H71.5071V10.1547Z"
          fill="#4B4A4B"
        />
        <path
          d="M87.2276 19.9253C87.2276 20.1002 87.1949 20.2626 87.1297 20.4125C87.0645 20.5623 86.9732 20.6935 86.8558 20.8059C86.745 20.9121 86.6113 20.9964 86.4547 21.0588C86.2982 21.1213 86.1287 21.1525 85.9461 21.1525H76.7701C76.5353 21.1525 76.281 21.1275 76.0071 21.0776C75.7397 21.0214 75.4723 20.9371 75.2049 20.8246C74.944 20.7122 74.6929 20.5686 74.4516 20.3937C74.2103 20.2126 73.9951 19.9972 73.806 19.7474C73.6234 19.4913 73.4767 19.1978 73.3658 18.8668C73.2549 18.5295 73.1995 18.1517 73.1995 17.7333V11.1383C73.1995 10.9135 73.2256 10.6731 73.2777 10.417C73.3364 10.1547 73.4245 9.89867 73.5419 9.64886C73.6593 9.39281 73.8125 9.14925 74.0016 8.91817C74.1908 8.6871 74.4158 8.48413 74.6766 8.30927C74.944 8.12815 75.2505 7.98452 75.5962 7.87835C75.9418 7.77218 76.3331 7.71909 76.7701 7.71909H87.1102V10.1547H76.7701C76.4375 10.1547 76.1831 10.239 76.0071 10.4077C75.831 10.5763 75.7429 10.8261 75.7429 11.1571V17.7333C75.7429 18.0455 75.831 18.2891 76.0071 18.464C76.1897 18.6326 76.444 18.7169 76.7701 18.7169H84.6841V15.663H77.9146V13.2086H85.9461C86.1287 13.2086 86.2982 13.243 86.4547 13.3117C86.6113 13.3741 86.745 13.4616 86.8558 13.574C86.9732 13.6864 87.0645 13.8175 87.1297 13.9674C87.1949 14.1111 87.2276 14.2672 87.2276 14.4358V19.9253Z"
          fill="#4B4A4B"
        />
        <path
          d="M102.762 21.1525H91.1601C90.9775 21.1525 90.8079 21.1213 90.6514 21.0588C90.4949 20.9964 90.3579 20.9121 90.2406 20.8059C90.1297 20.6935 90.0416 20.5623 89.9764 20.4125C89.9112 20.2626 89.8786 20.1002 89.8786 19.9253V7.71909H92.4221V18.7169H102.762V21.1525Z"
          fill="#4B4A4B"
        />
        <path
          d="M124.411 17.1993C124.411 17.6802 124.346 18.1142 124.215 18.5014C124.091 18.8824 123.925 19.2196 123.716 19.5132C123.508 19.8067 123.263 20.0565 122.983 20.2626C122.702 20.4687 122.412 20.6373 122.112 20.7684C121.812 20.8996 121.505 20.9964 121.192 21.0588C120.886 21.1213 120.596 21.1525 120.322 21.1525H110.51V18.7169H120.322C120.811 18.7169 121.189 18.5795 121.456 18.3047C121.73 18.0299 121.867 17.6615 121.867 17.1993C121.867 16.9745 121.831 16.7684 121.76 16.581C121.688 16.3937 121.584 16.2313 121.447 16.0939C121.316 15.9565 121.153 15.8503 120.958 15.7754C120.768 15.7005 120.556 15.663 120.322 15.663H114.472C114.061 15.663 113.617 15.5943 113.141 15.4569C112.665 15.3133 112.222 15.0853 111.811 14.773C111.407 14.4608 111.067 14.0517 110.794 13.5459C110.526 13.04 110.392 12.4217 110.392 11.691C110.392 10.9604 110.526 10.3452 110.794 9.84559C111.067 9.33972 111.407 8.93066 111.811 8.6184C112.222 8.2999 112.665 8.07195 113.141 7.93455C113.617 7.79091 114.061 7.71909 114.472 7.71909H123.129V10.1547H114.472C113.989 10.1547 113.611 10.2952 113.337 10.5763C113.07 10.8573 112.936 11.2289 112.936 11.691C112.936 12.1594 113.07 12.531 113.337 12.8058C113.611 13.0744 113.989 13.2086 114.472 13.2086H120.322H120.341C120.615 13.2149 120.905 13.2523 121.212 13.321C121.518 13.3835 121.822 13.4834 122.122 13.6208C122.428 13.7582 122.718 13.9331 122.992 14.1454C123.266 14.3515 123.508 14.6013 123.716 14.8948C123.931 15.1884 124.101 15.5256 124.225 15.9066C124.349 16.2875 124.411 16.7184 124.411 17.1993Z"
          fill="#4B4A4B"
        />
        <path
          d="M141.246 15.7192C141.246 16.5373 141.1 17.2899 140.806 17.9768C140.513 18.6638 140.105 19.2571 139.583 19.7567C139.062 20.2563 138.442 20.6467 137.725 20.9277C137.014 21.2025 136.238 21.3399 135.396 21.3399H131.953C131.112 21.3399 130.332 21.2025 129.615 20.9277C128.898 20.6467 128.278 20.2563 127.756 19.7567C127.235 19.2571 126.824 18.6638 126.524 17.9768C126.23 17.2899 126.084 16.5373 126.084 15.7192V13.1524C126.084 12.3405 126.23 11.5911 126.524 10.9041C126.824 10.2109 127.235 9.61764 127.756 9.12426C128.278 8.62465 128.898 8.23432 129.615 7.95329C130.332 7.67226 131.112 7.53174 131.953 7.53174H135.396C136.238 7.53174 137.014 7.67226 137.725 7.95329C138.442 8.23432 139.062 8.62465 139.583 9.12426C140.105 9.61764 140.513 10.2109 140.806 10.9041C141.1 11.5911 141.246 12.3405 141.246 13.1524V15.7192ZM138.703 13.1524C138.703 12.6715 138.621 12.2375 138.458 11.8503C138.302 11.4568 138.077 11.1227 137.783 10.8479C137.496 10.5669 137.148 10.3514 136.737 10.2016C136.332 10.0454 135.886 9.96737 135.396 9.96737H131.953C131.457 9.96737 131.004 10.0454 130.593 10.2016C130.189 10.3514 129.84 10.5669 129.547 10.8479C129.253 11.1227 129.025 11.4568 128.862 11.8503C128.705 12.2375 128.627 12.6715 128.627 13.1524V15.7192C128.627 16.2001 128.705 16.6372 128.862 17.0307C129.025 17.4179 129.253 17.752 129.547 18.033C129.84 18.3078 130.189 18.5233 130.593 18.6794C131.004 18.8293 131.457 18.9042 131.953 18.9042H135.377C135.873 18.9042 136.323 18.8293 136.727 18.6794C137.138 18.5233 137.49 18.3078 137.783 18.033C138.077 17.752 138.302 17.4179 138.458 17.0307C138.621 16.6372 138.703 16.2001 138.703 15.7192V13.1524Z"
          fill="#4B4A4B"
        />
        <path
          d="M156.644 21.1525H145.042C144.859 21.1525 144.69 21.1213 144.533 21.0588C144.377 20.9964 144.24 20.9121 144.122 20.8059C144.012 20.6935 143.924 20.5623 143.858 20.4125C143.793 20.2626 143.76 20.1002 143.76 19.9253V7.71909H146.304V18.7169H156.644V21.1525Z"
          fill="#4B4A4B"
        />
        <path
          d="M171.513 19.9253C171.513 20.1002 171.481 20.2626 171.416 20.4125C171.35 20.5623 171.259 20.6935 171.142 20.8059C171.024 20.9121 170.887 20.9964 170.731 21.0588C170.574 21.1213 170.408 21.1525 170.232 21.1525H164.499C163.958 21.1525 163.41 21.0963 162.856 20.9839C162.302 20.8715 161.764 20.6997 161.242 20.4687C160.727 20.2314 160.241 19.9316 159.784 19.5694C159.328 19.2071 158.927 18.7793 158.581 18.286C158.242 17.7864 157.974 17.218 157.779 16.581C157.583 15.9378 157.485 15.2227 157.485 14.4358V7.71909H160.029V14.4358C160.029 15.1228 160.127 15.7036 160.322 16.1782C160.524 16.6529 160.779 17.0494 161.085 17.3679C161.392 17.6864 161.728 17.9362 162.093 18.1174C162.458 18.2985 162.804 18.4327 163.13 18.5202C163.462 18.6076 163.753 18.6638 164 18.6888C164.255 18.7075 164.421 18.7169 164.499 18.7169H168.97V7.71909H171.513V19.9253Z"
          fill="#4B4A4B"
        />
        <path
          d="M187.156 10.1547H181.55V21.1525H179.007V10.1547H173.392V7.71909H187.156V10.1547Z"
          fill="#4B4A4B"
        />
        <path
          d="M191.597 21.1525H189.053V7.71909H191.597V21.1525Z"
          fill="#4B4A4B"
        />
        <path
          d="M209.293 15.7192C209.293 16.5373 209.147 17.2899 208.853 17.9768C208.56 18.6638 208.152 19.2571 207.63 19.7567C207.109 20.2563 206.489 20.6467 205.772 20.9277C205.061 21.2025 204.285 21.3399 203.443 21.3399H200C199.159 21.3399 198.379 21.2025 197.662 20.9277C196.945 20.6467 196.325 20.2563 195.803 19.7567C195.282 19.2571 194.871 18.6638 194.571 17.9768C194.277 17.2899 194.13 16.5373 194.13 15.7192V13.1524C194.13 12.3405 194.277 11.5911 194.571 10.9041C194.871 10.2109 195.282 9.61764 195.803 9.12426C196.325 8.62465 196.945 8.23432 197.662 7.95329C198.379 7.67226 199.159 7.53174 200 7.53174H203.443C204.285 7.53174 205.061 7.67226 205.772 7.95329C206.489 8.23432 207.109 8.62465 207.63 9.12426C208.152 9.61764 208.56 10.2109 208.853 10.9041C209.147 11.5911 209.293 12.3405 209.293 13.1524V15.7192ZM206.75 13.1524C206.75 12.6715 206.668 12.2375 206.505 11.8503C206.349 11.4568 206.124 11.1227 205.83 10.8479C205.543 10.5669 205.194 10.3514 204.784 10.2016C204.379 10.0454 203.933 9.96737 203.443 9.96737H200C199.504 9.96737 199.051 10.0454 198.64 10.2016C198.236 10.3514 197.887 10.5669 197.593 10.8479C197.3 11.1227 197.072 11.4568 196.909 11.8503C196.752 12.2375 196.674 12.6715 196.674 13.1524V15.7192C196.674 16.2001 196.752 16.6372 196.909 17.0307C197.072 17.4179 197.3 17.752 197.593 18.033C197.887 18.3078 198.236 18.5233 198.64 18.6794C199.051 18.8293 199.504 18.9042 200 18.9042H203.424C203.919 18.9042 204.369 18.8293 204.774 18.6794C205.185 18.5233 205.537 18.3078 205.83 18.033C206.124 17.752 206.349 17.4179 206.505 17.0307C206.668 16.6372 206.75 16.2001 206.75 15.7192V13.1524Z"
          fill="#4B4A4B"
        />
        <path
          d="M225.826 20.1127C225.826 20.2876 225.79 20.4499 225.718 20.5998C225.653 20.7497 225.562 20.8809 225.444 20.9933C225.333 21.0994 225.2 21.1837 225.043 21.2462C224.887 21.3087 224.72 21.3399 224.544 21.3399C224.388 21.3399 224.228 21.3118 224.065 21.2556C223.908 21.1994 223.768 21.1088 223.644 20.9839L214.351 11.691V21.1525H211.807V8.75892C211.807 8.50911 211.879 8.28428 212.023 8.08444C212.173 7.87835 212.362 7.72534 212.59 7.62542C212.831 7.53174 213.079 7.50988 213.333 7.55984C213.588 7.60356 213.806 7.71597 213.989 7.89708L223.282 17.1806V7.71909H225.826V20.1127Z"
          fill="#4B4A4B"
        />
        <path
          d="M242.27 17.1993C242.27 17.6802 242.205 18.1142 242.074 18.5014C241.951 18.8824 241.784 19.2196 241.576 19.5132C241.367 19.8067 241.122 20.0565 240.842 20.2626C240.561 20.4687 240.271 20.6373 239.971 20.7684C239.671 20.8996 239.365 20.9964 239.052 21.0588C238.745 21.1213 238.455 21.1525 238.181 21.1525H228.369V18.7169H238.181C238.67 18.7169 239.048 18.5795 239.316 18.3047C239.59 18.0299 239.727 17.6615 239.727 17.1993C239.727 16.9745 239.691 16.7684 239.619 16.581C239.547 16.3937 239.443 16.2313 239.306 16.0939C239.176 15.9565 239.012 15.8503 238.817 15.7754C238.628 15.7005 238.416 15.663 238.181 15.663H232.331C231.92 15.663 231.477 15.5943 231.001 15.4569C230.525 15.3133 230.081 15.0853 229.67 14.773C229.266 14.4608 228.927 14.0517 228.653 13.5459C228.385 13.04 228.252 12.4217 228.252 11.691C228.252 10.9604 228.385 10.3452 228.653 9.84559C228.927 9.33972 229.266 8.93066 229.67 8.6184C230.081 8.2999 230.525 8.07195 231.001 7.93455C231.477 7.79091 231.92 7.71909 232.331 7.71909H240.989V10.1547H232.331C231.848 10.1547 231.47 10.2952 231.196 10.5763C230.929 10.8573 230.795 11.2289 230.795 11.691C230.795 12.1594 230.929 12.531 231.196 12.8058C231.47 13.0744 231.848 13.2086 232.331 13.2086H238.181H238.201C238.474 13.2149 238.765 13.2523 239.071 13.321C239.378 13.3835 239.681 13.4834 239.981 13.6208C240.287 13.7582 240.578 13.9331 240.852 14.1454C241.126 14.3515 241.367 14.6013 241.576 14.8948C241.791 15.1884 241.96 15.5256 242.084 15.9066C242.208 16.2875 242.27 16.7184 242.27 17.1993Z"
          fill="#4B4A4B"
        />
        <path
          d="M61.4313 39.3059C60.7065 39.3059 60.0047 39.1968 59.3258 38.9785C58.656 38.7512 58.1285 38.4602 57.7432 38.1056L58.2523 37.0417C58.6193 37.3691 59.0872 37.6373 59.656 37.8465C60.234 38.0465 60.8258 38.1465 61.4313 38.1465C62.2295 38.1465 62.8258 38.0147 63.2203 37.751C63.6148 37.4782 63.8121 37.119 63.8121 36.6734C63.8121 36.3461 63.702 36.0824 63.4818 35.8823C63.2708 35.6732 63.0047 35.5141 62.6836 35.405C62.3717 35.2958 61.9267 35.1731 61.3487 35.0367C60.6239 34.8639 60.0368 34.6912 59.5872 34.5184C59.1469 34.3456 58.7661 34.0819 58.445 33.7273C58.1331 33.3636 57.9771 32.8771 57.9771 32.2678C57.9771 31.7586 58.1101 31.2994 58.3762 30.8902C58.6514 30.4811 59.0643 30.1537 59.6148 29.9082C60.1652 29.6627 60.8487 29.5399 61.6653 29.5399C62.2341 29.5399 62.7937 29.6127 63.3442 29.7582C63.8946 29.9036 64.3671 30.1128 64.7616 30.3856L64.3075 31.4767C63.9038 31.2221 63.4726 31.0312 63.0139 30.9039C62.5552 30.7675 62.1056 30.6993 61.6653 30.6993C60.8854 30.6993 60.2983 30.8402 59.9038 31.1221C59.5184 31.404 59.3258 31.7677 59.3258 32.2133C59.3258 32.5406 59.4359 32.8089 59.656 33.018C59.8762 33.2181 60.1469 33.3772 60.468 33.4954C60.7983 33.6045 61.2432 33.7227 61.8029 33.85C62.5277 34.0228 63.1102 34.1956 63.5506 34.3683C63.991 34.5411 64.3671 34.8048 64.6791 35.1594C65.0002 35.5141 65.1607 35.9915 65.1607 36.5916C65.1607 37.0917 65.0231 37.5509 64.7479 37.9692C64.4726 38.3784 64.0552 38.7057 63.4956 38.9513C62.9359 39.1877 62.2478 39.3059 61.4313 39.3059Z"
          fill="#ff6e26"
        />
        <path
          d="M76.1026 31.8996C77.02 31.8996 77.7448 32.1633 78.2769 32.6907C78.8182 33.2181 79.0889 34.0001 79.0889 35.0367V39.1968H77.7677V35.1867C77.7677 34.4866 77.6026 33.9592 77.2723 33.6045C76.9512 33.2499 76.4879 33.0726 75.8824 33.0726C75.2127 33.0726 74.6805 33.2817 74.286 33.7C73.8915 34.1092 73.6943 34.7002 73.6943 35.4732V39.1968H72.3732V35.1867C72.3732 34.4866 72.208 33.9592 71.8777 33.6045C71.5566 33.2499 71.0933 33.0726 70.4878 33.0726C69.8181 33.0726 69.2859 33.2817 68.8914 33.7C68.4969 34.1092 68.2997 34.7002 68.2997 35.4732V39.1968H66.9786V31.9678H68.2446V33.0453C68.5107 32.6725 68.8593 32.3906 69.2905 32.1997C69.7217 31.9996 70.2126 31.8996 70.763 31.8996C71.3319 31.8996 71.8364 32.0132 72.2768 32.2406C72.7172 32.4679 73.0567 32.7998 73.2952 33.2363C73.5704 32.818 73.9512 32.4906 74.4374 32.2542C74.9328 32.0178 75.4879 31.8996 76.1026 31.8996Z"
          fill="#ff6e26"
        />
        <path
          d="M84.1758 31.8996C85.1849 31.8996 85.9556 32.1451 86.4877 32.6361C87.029 33.1271 87.2997 33.8591 87.2997 34.8321V39.1968H86.0474V38.242C85.8272 38.5784 85.5106 38.8376 85.0978 39.0195C84.6941 39.1922 84.2125 39.2786 83.6528 39.2786C82.8363 39.2786 82.1803 39.0831 81.6849 38.6921C81.1986 38.3011 80.9555 37.7873 80.9555 37.1508C80.9555 36.5143 81.1895 36.0051 81.6574 35.6232C82.1253 35.2322 82.8684 35.0367 83.8868 35.0367H85.9785V34.7775C85.9785 34.2138 85.8134 33.7818 85.4831 33.4818C85.1528 33.1817 84.6666 33.0317 84.0244 33.0317C83.5932 33.0317 83.1712 33.1044 82.7583 33.2499C82.3454 33.3863 81.9968 33.5727 81.7124 33.8091L81.1619 32.8271C81.5381 32.527 81.9876 32.2997 82.5106 32.1451C83.0335 31.9814 83.5886 31.8996 84.1758 31.8996ZM83.873 38.2693C84.3776 38.2693 84.8134 38.1602 85.1804 37.9419C85.5473 37.7146 85.8134 37.3963 85.9785 36.9872V35.9778H83.9418C82.8225 35.9778 82.2629 36.3506 82.2629 37.0963C82.2629 37.46 82.4051 37.7464 82.6895 37.9556C82.9739 38.1647 83.3684 38.2693 83.873 38.2693Z"
          fill="#ff6e26"
        />
        <path
          d="M91.0106 33.1817C91.24 32.7634 91.5794 32.4452 92.029 32.2269C92.4785 32.0087 93.0244 31.8996 93.6666 31.8996V33.1681C93.5932 33.159 93.4923 33.1544 93.3638 33.1544C92.6482 33.1544 92.084 33.3681 91.6712 33.7955C91.2675 34.2138 91.0656 34.8139 91.0656 35.5959V39.1968H89.7445V31.9678H91.0106V33.1817Z"
          fill="#ff6e26"
        />
        <path
          d="M99.6915 38.7739C99.4989 38.9376 99.2603 39.0649 98.9759 39.1559C98.6915 39.2377 98.3979 39.2786 98.0952 39.2786C97.3612 39.2786 96.7924 39.0831 96.3887 38.6921C95.9851 38.3011 95.7832 37.7419 95.7832 37.0144V33.0453H94.5447V31.9678H95.7832V30.3856H97.1043V31.9678H99.1961V33.0453H97.1043V36.9599C97.1043 37.3509 97.2007 37.651 97.3933 37.8601C97.5952 38.0692 97.8796 38.1738 98.2466 38.1738C98.6502 38.1738 98.9943 38.0601 99.2787 37.8328L99.6915 38.7739Z"
          fill="#ff6e26"
        />
        <path
          d="M112.223 39.3468L110.943 38.0783C110.09 38.8967 109.017 39.3059 107.723 39.3059C107.081 39.3059 106.503 39.2013 105.989 38.9922C105.475 38.783 105.072 38.4921 104.778 38.1192C104.494 37.7373 104.352 37.3054 104.352 36.8235C104.352 36.2415 104.53 35.7278 104.888 35.2822C105.255 34.8276 105.856 34.3638 106.691 33.891C106.269 33.4727 105.971 33.0953 105.796 32.7589C105.622 32.4224 105.535 32.0723 105.535 31.7086C105.535 31.063 105.769 30.5447 106.237 30.1537C106.714 29.7627 107.347 29.5672 108.136 29.5672C108.87 29.5672 109.453 29.7445 109.884 30.0991C110.315 30.4538 110.531 30.9403 110.531 31.5586C110.531 32.0405 110.37 32.4724 110.049 32.8544C109.737 33.2363 109.214 33.6364 108.48 34.0546L110.861 36.4007C111.136 35.8823 111.333 35.3049 111.453 34.6684L112.512 35.0094C112.347 35.846 112.067 36.5734 111.673 37.1918L112.966 38.4739L112.223 39.3468ZM108.136 30.522C107.705 30.522 107.365 30.6311 107.118 30.8493C106.879 31.0585 106.76 31.3358 106.76 31.6813C106.76 31.9269 106.824 32.1633 106.952 32.3906C107.09 32.6179 107.352 32.9271 107.737 33.3181C108.37 32.9635 108.806 32.6589 109.044 32.4042C109.292 32.1496 109.416 31.8723 109.416 31.5722C109.416 31.254 109.301 30.9994 109.072 30.8084C108.852 30.6175 108.54 30.522 108.136 30.522ZM107.806 38.2147C108.76 38.2147 109.544 37.9147 110.159 37.3145L107.42 34.6139C106.76 34.9776 106.296 35.3186 106.03 35.6368C105.774 35.946 105.645 36.2961 105.645 36.6871C105.645 37.1417 105.842 37.51 106.237 37.7919C106.641 38.0738 107.163 38.2147 107.806 38.2147Z"
          fill="#ff6e26"
        />
        <path
          d="M121.115 39.3059C120.39 39.3059 119.688 39.1968 119.009 38.9785C118.339 38.7512 117.812 38.4602 117.427 38.1056L117.936 37.0417C118.303 37.3691 118.771 37.6373 119.339 37.8465C119.917 38.0465 120.509 38.1465 121.115 38.1465C121.913 38.1465 122.509 38.0147 122.904 37.751C123.298 37.4782 123.495 37.119 123.495 36.6734C123.495 36.3461 123.385 36.0824 123.165 35.8823C122.954 35.6732 122.688 35.5141 122.367 35.405C122.055 35.2958 121.61 35.1731 121.032 35.0367C120.307 34.8639 119.72 34.6912 119.271 34.5184C118.83 34.3456 118.449 34.0819 118.128 33.7273C117.816 33.3636 117.66 32.8771 117.66 32.2678C117.66 31.7586 117.794 31.2994 118.06 30.8902C118.335 30.4811 118.748 30.1537 119.298 29.9082C119.849 29.6627 120.532 29.5399 121.349 29.5399C121.917 29.5399 122.477 29.6127 123.028 29.7582C123.578 29.9036 124.05 30.1128 124.445 30.3856L123.991 31.4767C123.587 31.2221 123.156 31.0312 122.697 30.9039C122.239 30.7675 121.789 30.6993 121.349 30.6993C120.569 30.6993 119.982 30.8402 119.587 31.1221C119.202 31.404 119.009 31.7677 119.009 32.2133C119.009 32.5406 119.119 32.8089 119.339 33.018C119.56 33.2181 119.83 33.3772 120.151 33.4954C120.482 33.6045 120.927 33.7227 121.486 33.85C122.211 34.0228 122.794 34.1956 123.234 34.3683C123.674 34.5411 124.05 34.8048 124.362 35.1594C124.684 35.5141 124.844 35.9915 124.844 36.5916C124.844 37.0917 124.706 37.5509 124.431 37.9692C124.156 38.3784 123.739 38.7057 123.179 38.9513C122.619 39.1877 121.931 39.3059 121.115 39.3059Z"
          fill="#ff6e26"
        />
        <path
          d="M130.529 31.8996C131.235 31.8996 131.868 32.0542 132.428 32.3633C132.988 32.6725 133.424 33.1044 133.735 33.6591C134.057 34.2138 134.217 34.8548 134.217 35.5823C134.217 36.3097 134.057 36.9553 133.735 37.5191C133.424 38.0738 132.988 38.5057 132.428 38.8149C131.868 39.124 131.235 39.2786 130.529 39.2786C130.006 39.2786 129.524 39.1786 129.084 38.9785C128.653 38.7785 128.286 38.4875 127.983 38.1056V41.8429H126.662V31.9678H127.928V33.1135C128.222 32.7134 128.593 32.4133 129.043 32.2133C129.492 32.0041 129.988 31.8996 130.529 31.8996ZM130.419 38.1329C130.887 38.1329 131.304 38.0283 131.671 37.8192C132.047 37.6009 132.341 37.3009 132.552 36.919C132.772 36.528 132.882 36.0824 132.882 35.5823C132.882 35.0822 132.772 34.6411 132.552 34.2592C132.341 33.8682 132.047 33.5682 131.671 33.359C131.304 33.1499 130.887 33.0453 130.419 33.0453C129.96 33.0453 129.543 33.1544 129.167 33.3727C128.8 33.5818 128.506 33.8773 128.286 34.2592C128.075 34.6411 127.969 35.0822 127.969 35.5823C127.969 36.0824 128.075 36.528 128.286 36.919C128.497 37.3009 128.79 37.6009 129.167 37.8192C129.543 38.0283 129.96 38.1329 130.419 38.1329Z"
          fill="#ff6e26"
        />
        <path
          d="M142.634 35.6232C142.634 35.7232 142.625 35.8551 142.607 36.0187H136.689C136.772 36.6553 137.052 37.169 137.529 37.56C138.015 37.9419 138.616 38.1329 139.332 38.1329C140.203 38.1329 140.905 37.8419 141.437 37.2599L142.166 38.1056C141.836 38.4875 141.423 38.7785 140.928 38.9785C140.442 39.1786 139.896 39.2786 139.29 39.2786C138.52 39.2786 137.836 39.124 137.24 38.8149C136.643 38.4966 136.18 38.0556 135.85 37.4918C135.529 36.9281 135.368 36.2915 135.368 35.5823C135.368 34.8821 135.524 34.2501 135.836 33.6864C136.157 33.1226 136.593 32.6861 137.143 32.377C137.703 32.0587 138.332 31.8996 139.029 31.8996C139.726 31.8996 140.345 32.0587 140.887 32.377C141.437 32.6861 141.864 33.1226 142.166 33.6864C142.478 34.2501 142.634 34.8957 142.634 35.6232ZM139.029 33.0044C138.396 33.0044 137.864 33.1953 137.432 33.5773C137.01 33.9592 136.763 34.4593 136.689 35.0776H141.368C141.295 34.4684 141.043 33.9728 140.611 33.5909C140.189 33.1999 139.662 33.0044 139.029 33.0044Z"
          fill="#ff6e26"
        />
        <path
          d="M151.061 35.6232C151.061 35.7232 151.052 35.8551 151.033 36.0187H145.116C145.198 36.6553 145.478 37.169 145.955 37.56C146.441 37.9419 147.042 38.1329 147.758 38.1329C148.629 38.1329 149.331 37.8419 149.863 37.2599L150.593 38.1056C150.263 38.4875 149.85 38.7785 149.354 38.9785C148.868 39.1786 148.322 39.2786 147.717 39.2786C146.946 39.2786 146.262 39.124 145.666 38.8149C145.07 38.4966 144.606 38.0556 144.276 37.4918C143.955 36.9281 143.795 36.2915 143.795 35.5823C143.795 34.8821 143.95 34.2501 144.262 33.6864C144.584 33.1226 145.019 32.6861 145.57 32.377C146.129 32.0587 146.758 31.8996 147.455 31.8996C148.152 31.8996 148.772 32.0587 149.313 32.377C149.863 32.6861 150.29 33.1226 150.593 33.6864C150.905 34.2501 151.061 34.8957 151.061 35.6232ZM147.455 33.0044C146.822 33.0044 146.29 33.1953 145.859 33.5773C145.437 33.9592 145.189 34.4593 145.116 35.0776H149.795C149.721 34.4684 149.469 33.9728 149.038 33.5909C148.616 33.1999 148.088 33.0044 147.455 33.0044Z"
          fill="#ff6e26"
        />
        <path
          d="M159.776 29.0762V39.1968H158.51V38.051C158.216 38.4511 157.845 38.7558 157.395 38.9649C156.946 39.174 156.45 39.2786 155.909 39.2786C155.203 39.2786 154.57 39.124 154.01 38.8149C153.45 38.5057 153.01 38.0738 152.689 37.5191C152.377 36.9553 152.221 36.3097 152.221 35.5823C152.221 34.8548 152.377 34.2138 152.689 33.6591C153.01 33.1044 153.45 32.6725 154.01 32.3633C154.57 32.0542 155.203 31.8996 155.909 31.8996C156.432 31.8996 156.914 31.9996 157.354 32.1997C157.794 32.3906 158.161 32.677 158.455 33.0589V29.0762H159.776ZM156.019 38.1329C156.478 38.1329 156.895 38.0283 157.271 37.8192C157.648 37.6009 157.941 37.3009 158.152 36.919C158.363 36.528 158.469 36.0824 158.469 35.5823C158.469 35.0822 158.363 34.6411 158.152 34.2592C157.941 33.8682 157.648 33.5682 157.271 33.359C156.895 33.1499 156.478 33.0453 156.019 33.0453C155.551 33.0453 155.129 33.1499 154.753 33.359C154.386 33.5682 154.092 33.8682 153.872 34.2592C153.661 34.6411 153.556 35.0822 153.556 35.5823C153.556 36.0824 153.661 36.528 153.872 36.919C154.092 37.3009 154.386 37.6009 154.753 37.8192C155.129 38.0283 155.551 38.1329 156.019 38.1329Z"
          fill="#ff6e26"
        />
        <path
          d="M168.972 39.3059C168.247 39.3059 167.545 39.1968 166.866 38.9785C166.196 38.7512 165.669 38.4602 165.283 38.1056L165.793 37.0417C166.16 37.3691 166.627 37.6373 167.196 37.8465C167.774 38.0465 168.366 38.1465 168.972 38.1465C169.77 38.1465 170.366 38.0147 170.761 37.751C171.155 37.4782 171.352 37.119 171.352 36.6734C171.352 36.3461 171.242 36.0824 171.022 35.8823C170.811 35.6732 170.545 35.5141 170.224 35.405C169.912 35.2958 169.467 35.1731 168.889 35.0367C168.164 34.8639 167.577 34.6912 167.127 34.5184C166.687 34.3456 166.306 34.0819 165.985 33.7273C165.673 33.3636 165.517 32.8771 165.517 32.2678C165.517 31.7586 165.65 31.2994 165.916 30.8902C166.192 30.4811 166.605 30.1537 167.155 29.9082C167.705 29.6627 168.389 29.5399 169.206 29.5399C169.774 29.5399 170.334 29.6127 170.884 29.7582C171.435 29.9036 171.907 30.1128 172.302 30.3856L171.848 31.4767C171.444 31.2221 171.013 31.0312 170.554 30.9039C170.095 30.7675 169.646 30.6993 169.206 30.6993C168.426 30.6993 167.839 30.8402 167.444 31.1221C167.059 31.404 166.866 31.7677 166.866 32.2133C166.866 32.5406 166.976 32.8089 167.196 33.018C167.416 33.2181 167.687 33.3772 168.008 33.4954C168.339 33.6045 168.783 33.7227 169.343 33.85C170.068 34.0228 170.65 34.1956 171.091 34.3683C171.531 34.5411 171.907 34.8048 172.219 35.1594C172.54 35.5141 172.701 35.9915 172.701 36.5916C172.701 37.0917 172.563 37.5509 172.288 37.9692C172.013 38.3784 171.595 38.7057 171.036 38.9513C170.476 39.1877 169.788 39.3059 168.972 39.3059Z"
          fill="#ff6e26"
        />
        <path
          d="M177.643 39.2786C176.918 39.2786 176.267 39.1195 175.689 38.8012C175.111 38.483 174.656 38.0465 174.326 37.4918C174.005 36.9281 173.844 36.2915 173.844 35.5823C173.844 34.873 174.005 34.241 174.326 33.6864C174.656 33.1226 175.111 32.6861 175.689 32.377C176.267 32.0587 176.918 31.8996 177.643 31.8996C178.368 31.8996 179.014 32.0587 179.583 32.377C180.161 32.6861 180.611 33.1226 180.932 33.6864C181.262 34.241 181.427 34.873 181.427 35.5823C181.427 36.2915 181.262 36.9281 180.932 37.4918C180.611 38.0465 180.161 38.483 179.583 38.8012C179.014 39.1195 178.368 39.2786 177.643 39.2786ZM177.643 38.1329C178.111 38.1329 178.528 38.0283 178.895 37.8192C179.271 37.6009 179.565 37.3009 179.776 36.919C179.987 36.528 180.092 36.0824 180.092 35.5823C180.092 35.0822 179.987 34.6411 179.776 34.2592C179.565 33.8682 179.271 33.5682 178.895 33.359C178.528 33.1499 178.111 33.0453 177.643 33.0453C177.175 33.0453 176.753 33.1499 176.377 33.359C176.01 33.5682 175.716 33.8682 175.496 34.2592C175.285 34.6411 175.179 35.0822 175.179 35.5823C175.179 36.0824 175.285 36.528 175.496 36.919C175.716 37.3009 176.01 37.6009 176.377 37.8192C176.753 38.0283 177.175 38.1329 177.643 38.1329Z"
          fill="#ff6e26"
        />
        <path
          d="M183.254 29.0762H184.575V39.1968H183.254V29.0762Z"
          fill="#ff6e26"
        />
        <path
          d="M193.896 31.9678V39.1968H192.644V38.1056C192.378 38.4784 192.025 38.7694 191.584 38.9785C191.153 39.1786 190.68 39.2786 190.167 39.2786C189.194 39.2786 188.428 39.0149 187.869 38.4875C187.309 37.951 187.029 37.1645 187.029 36.1279V31.9678H188.35V35.9778C188.35 36.678 188.52 37.2099 188.859 37.5737C189.199 37.9283 189.685 38.1056 190.318 38.1056C191.015 38.1056 191.566 37.8965 191.97 37.4782C192.373 37.0599 192.575 36.4689 192.575 35.705V31.9678H193.896Z"
          fill="#ff6e26"
        />
        <path
          d="M200.512 38.7739C200.32 38.9376 200.081 39.0649 199.797 39.1559C199.512 39.2377 199.219 39.2786 198.916 39.2786C198.182 39.2786 197.613 39.0831 197.209 38.6921C196.806 38.3011 196.604 37.7419 196.604 37.0144V33.0453H195.365V31.9678H196.604V30.3856H197.925V31.9678H200.017V33.0453H197.925V36.9599C197.925 37.3509 198.021 37.651 198.214 37.8601C198.416 38.0692 198.7 38.1738 199.067 38.1738C199.471 38.1738 199.815 38.0601 200.099 37.8328L200.512 38.7739Z"
          fill="#ff6e26"
        />
        <path
          d="M202.109 31.9678H203.431V39.1968H202.109V31.9678ZM202.77 30.5765C202.513 30.5765 202.298 30.4947 202.123 30.331C201.958 30.1673 201.875 29.9673 201.875 29.7309C201.875 29.4945 201.958 29.2944 202.123 29.1307C202.298 28.958 202.513 28.8716 202.77 28.8716C203.027 28.8716 203.238 28.9534 203.403 29.1171C203.577 29.2717 203.665 29.4672 203.665 29.7036C203.665 29.9491 203.577 30.1583 203.403 30.331C203.238 30.4947 203.027 30.5765 202.77 30.5765Z"
          fill="#ff6e26"
        />
        <path
          d="M209.077 39.2786C208.352 39.2786 207.701 39.1195 207.123 38.8012C206.545 38.483 206.091 38.0465 205.76 37.4918C205.439 36.9281 205.279 36.2915 205.279 35.5823C205.279 34.873 205.439 34.241 205.76 33.6864C206.091 33.1226 206.545 32.6861 207.123 32.377C207.701 32.0587 208.352 31.8996 209.077 31.8996C209.802 31.8996 210.449 32.0587 211.017 32.377C211.595 32.6861 212.045 33.1226 212.366 33.6864C212.696 34.241 212.861 34.873 212.861 35.5823C212.861 36.2915 212.696 36.9281 212.366 37.4918C212.045 38.0465 211.595 38.483 211.017 38.8012C210.449 39.1195 209.802 39.2786 209.077 39.2786ZM209.077 38.1329C209.545 38.1329 209.962 38.0283 210.329 37.8192C210.705 37.6009 210.999 37.3009 211.21 36.919C211.421 36.528 211.527 36.0824 211.527 35.5823C211.527 35.0822 211.421 34.6411 211.21 34.2592C210.999 33.8682 210.705 33.5682 210.329 33.359C209.962 33.1499 209.545 33.0453 209.077 33.0453C208.609 33.0453 208.187 33.1499 207.811 33.359C207.444 33.5682 207.15 33.8682 206.93 34.2592C206.719 34.6411 206.614 35.0822 206.614 35.5823C206.614 36.0824 206.719 36.528 206.93 36.919C207.15 37.3009 207.444 37.6009 207.811 37.8192C208.187 38.0283 208.609 38.1329 209.077 38.1329Z"
          fill="#ff6e26"
        />
        <path
          d="M218.583 31.8996C219.51 31.8996 220.244 32.1678 220.785 32.7043C221.335 33.2317 221.611 34.0092 221.611 35.0367V39.1968H220.29V35.1867C220.29 34.4866 220.12 33.9592 219.78 33.6045C219.441 33.2499 218.955 33.0726 218.322 33.0726C217.606 33.0726 217.042 33.2817 216.629 33.7C216.216 34.1092 216.01 34.7002 216.01 35.4732V39.1968H214.688V31.9678H215.955V33.0589C216.221 32.6861 216.578 32.3997 217.028 32.1997C217.487 31.9996 218.005 31.8996 218.583 31.8996Z"
          fill="#ff6e26"
        />
        <path
          d="M226.147 39.2786C225.551 39.2786 224.978 39.2013 224.427 39.0467C223.877 38.8831 223.445 38.6785 223.134 38.433L223.684 37.3963C224.005 37.6237 224.395 37.8055 224.854 37.9419C225.312 38.0783 225.776 38.1465 226.244 38.1465C227.4 38.1465 227.978 37.8192 227.978 37.1645C227.978 36.9462 227.9 36.7735 227.744 36.6462C227.588 36.5189 227.391 36.4279 227.152 36.3734C226.923 36.3097 226.592 36.2415 226.161 36.1688C225.574 36.0778 225.092 35.9733 224.716 35.8551C224.349 35.7369 224.033 35.5368 223.767 35.2549C223.501 34.973 223.368 34.5775 223.368 34.0683C223.368 33.4136 223.643 32.8907 224.193 32.4997C224.744 32.0996 225.482 31.8996 226.409 31.8996C226.895 31.8996 227.381 31.9587 227.868 32.0769C228.354 32.1951 228.753 32.3542 229.065 32.5543L228.501 33.5909C227.904 33.209 227.202 33.018 226.395 33.018C225.835 33.018 225.409 33.109 225.115 33.2908C224.822 33.4727 224.675 33.7136 224.675 34.0137C224.675 34.2501 224.757 34.4365 224.923 34.5729C225.088 34.7093 225.29 34.8094 225.528 34.873C225.776 34.9367 226.12 35.0094 226.56 35.0912C227.147 35.1913 227.62 35.3004 227.978 35.4186C228.345 35.5277 228.657 35.7187 228.913 35.9915C229.17 36.2643 229.299 36.6462 229.299 37.1372C229.299 37.7919 229.014 38.3147 228.446 38.7057C227.886 39.0877 227.12 39.2786 226.147 39.2786Z"
          fill="#ff6e26"
        />
        <path
          d="M12.4772 38.0346C19.4526 41.078 25.0788 39.3609 28.4023 38.2913C14.048 41.7694 3.71369 31.7359 5.13294 19.6038C6.17405 10.7042 12.9761 7.08862 16.1955 5.65145C16.1041 6.51035 16.0392 8.61524 16.5114 10.1637C18.1815 5.13143 21.1657 2.33579 25.0535 0.338833C10.0242 -2.04309 3.01338 8.77927 1.59359 12.6424C-1.6771 21.5417 1.47928 33.236 12.4772 38.0346Z"
          fill="#ff6e26"
        />
        <path
          d="M22.8099 10.495C21.4437 10.6866 18.7974 11.8414 16.7033 15.0956C20.5907 12.5085 22.5868 12.8006 24.8834 12.9316C25.231 10.4985 27.1514 8.91078 28.892 8.66664C30.3972 8.23696 33.8161 8.75177 34.7795 12.5601C35.7077 16.2287 31.8596 18.8074 29.6796 19.8434C27.6499 20.6758 23.443 22.9692 22.0001 24.9242C18.5939 29.5394 19.9637 32.9166 22.7838 30.5118C25.04 28.5879 32.6857 27.3407 36.7556 26.6477C35.9501 31.4507 33.3372 40.3574 21.3267 42.042C12.0647 43.3412 6.21545 38.0369 3.8087 34.9058C10.4599 45.6303 26.6348 48.193 37.5472 39.7668C46.2771 33.0259 46.6543 22.1729 45.7516 17.589C44.0089 7.74252 37.0598 2.96945 31.3714 3.3895C25.683 3.80954 23.7106 6.91198 22.8099 10.495Z"
          fill="#002A9C"
        />
        <path
          d="M27.9842 12.3468C27.9842 13.1572 28.6411 13.8142 29.4516 13.8142C30.262 13.8142 30.9189 13.1572 30.9189 12.3468C30.9189 11.5364 30.262 10.8794 29.4516 10.8794C28.6411 10.8794 27.9842 11.5364 27.9842 12.3468Z"
          fill="#002A9C"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  created() {
    document.body.classList.add("no-scroll");
  },
  mounted() {
    window.onload = () => {
      document.body.classList.remove("no-scroll");
      document.getElementById("loading-screen").classList.remove("visible");
    };
  },
};
</script>

<style>
.no-scroll {
  overflow: hidden;
  height: 100%;
}
#loading-screen {
  position: fixed;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
  transition: bottom 0.6s cubic-bezier(0.32, 0, 0.67, 0), opacity 1s ease;
  opacity: 0.55;
}

#loading-screen.visible {
  bottom: 0%;
  opacity: 1;
}
</style>
