import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  // lang: localStorage.lang || 'vn',
  lang: 'vn'
};

const mutations = {
  SET_LANG(state, payload) {
    state.lang = payload;
  }
};

const actions = {
  setLang({ commit }, payload) {
    commit('SET_LANG', payload);
  }
};

const getters = {
  getLang: (state) => {
    return state.lang;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
