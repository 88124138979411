<template>
  <button id="btn-fly" aria-label="To Contact" class="btn-fly" @click="toContact">
    <svg
      width="50"
      height="48"
      viewBox="0 0 50 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4772 38.0346C19.4526 41.078 25.0788 39.3609 28.4023 38.2913C14.048 41.7694 3.71369 31.7359 5.13294 19.6038C6.17405 10.7042 12.9761 7.08862 16.1955 5.65145C16.1041 6.51035 16.0392 8.61524 16.5114 10.1637C18.1815 5.13143 21.1657 2.33579 25.0535 0.338833C10.0242 -2.04309 3.01338 8.77927 1.59359 12.6424C-1.6771 21.5417 1.47928 33.236 12.4772 38.0346Z"
        fill="#ff6e26"
      />
      <path
        d="M22.8099 10.495C21.4437 10.6867 18.7974 11.8414 16.7033 15.0956C20.5907 12.5085 22.5868 12.8006 24.8834 12.9316C25.231 10.4985 27.1514 8.91081 28.892 8.66667C30.3972 8.23699 33.8161 8.7518 34.7795 12.5601C35.7077 16.2288 31.8596 18.8074 29.6796 19.8435C27.6499 20.6759 23.443 22.9692 22.0001 24.9243C18.5939 29.5394 19.9637 32.9167 22.7838 30.5118C25.04 28.5879 32.6857 27.3408 36.7556 26.6478C35.9501 31.4507 33.3372 40.3574 21.3267 42.0421C12.0647 43.3412 6.21545 38.037 3.8087 34.9058C10.4599 45.6303 26.6348 48.193 37.5472 39.7669C46.2771 33.0259 46.6543 22.173 45.7516 17.5891C44.0089 7.74255 37.0598 2.96948 31.3714 3.38953C25.683 3.80957 23.7106 6.91201 22.8099 10.495Z"
        fill="#002A9C"
      />
      <path
        d="M27.9842 12.3466C27.9842 13.157 28.6411 13.814 29.4516 13.814C30.262 13.814 30.9189 13.157 30.9189 12.3466C30.9189 11.5362 30.262 10.8792 29.4516 10.8792C28.6411 10.8792 27.9842 11.5362 27.9842 12.3466Z"
        fill="#002A9C"
      />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'ButtonFly',
  data() {
    return {
      fab: true,
    };
  },
  methods: {
    // showPenguinFly() {
    //   this.fab = false;
    //   document
    //     ?.getElementsByClassName('penguin-fly-image')[4]
    //     ?.classList.add('fly-to-recument');
    //   setTimeout(() => {
    //     document
    //       ?.getElementsByClassName('penguin-fly-image')[4]
    //       ?.classList.remove('fly-to-recument');
    //   }, 3000);
    //   setTimeout(() => {
    //     document
    //       ?.getElementsByClassName('nav-item')[4]
    //       ?.classList.add('highlight');
    //   }, 2000);
    //   setTimeout(() => {
    //     document
    //       ?.getElementsByClassName('nav-item')[4]
    //       ?.classList.remove('highlight');
    //     this.fab = true;
    //   }, 4000);
    // },
    toContact() {
      this.$router.push('/contact');
    },
  },
};
</script>

<style scoped>
.btn-fly {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
  height: 50px;
  width: 50px;
  border: none;
  border-radius: 50%;
  bottom: 144px;
  right: 5%;
  background: #fff;
  position: fixed;
  z-index: 100;
}

@media (max-width: 1024px) {
  .btn-fly {
    display: none;
  }
}
</style>
