<template>
  <div id="app" class="v-content__wrap">
    <ProgressBar></ProgressBar>
    <div style="max-width: 2560px; margin: 0 auto">
      <Header></Header>
      <router-view />
      <BackToTop></BackToTop>
      <ButtonFly></ButtonFly>
    </div>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import Header from './components/Header';
import BackToTop from './components/BackToTop.vue';
import ButtonFly from './components/ButtonFly.vue';
import ProgressBar from './components/ProgressBar.vue';

export default {
  name: 'App',
  components: {
    Header,
    // Footer,
    BackToTop,
    ButtonFly,
    ProgressBar,
  },

  data: () => ({}),
  methods: {},
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      next();
    });

    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
};
</script>

<style scoped>
.content {
  background-color: white;
}
</style>
<style lang="scss">
@import './fonts.css';
//@import url('https://fonts.googleapis.com/css2?family=Birthstone+Bounce:wght@400;500&family=Fahkwang:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
body {
  font-size: 18px;
  //line-height: unset !important;
  color: var(--grey-text);
  padding: 0 !important;
  overflow: overlay;
}
.theme--light.v-application {
  color: var(--grey-text) !important;
}
:root {
  --white: #fff;
  --blue-dark: #001753;
  --blue-light: #002382;
  --blue-text: #002a9c;
  --orange: #ff6e26;
  --orange-background: #fff2eb;
  --grey-text: #0a0a0a;
  --brown-text: #4f4f4f;
  --black-text: #0a0a0a;
  --box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

*,
.v-application .title {
  font-family: 'Mulish', sans-serif !important;
}
a:hover {
  text-decoration: none !important;
}

/*
 *  STYLE 9
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.015);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
</style>
