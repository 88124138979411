<template>
  <button class="back-to-top" v-show="showButton" @click="scrollToTop">
    <svg
      :width="24"
      :height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#ffffff"
      strokeWidth="{2}"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 19V5M5 12l7-7 7 7" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'BackToTop',
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Kiểm tra vị trí cuộn trang
      if (window.pageYOffset > 300) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    scrollToTop() {
      // Cuộn trang lên đầu
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 50px;
  right: 5%;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: #002a9c;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
}
@media (max-width: 576px) {
  .back-to-top {
    opacity: 0.8;
  }
}
</style>
